import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BadgeOldModule } from 'src/app/shared/badge-old/badge-old.module';
import { SvgIconModule } from 'src/app/shared/icons/svg-icon/svg-icon.module';
import { CompanyVerificationBadgeComponent } from './company-verification-badge.component';

@NgModule({
  declarations: [CompanyVerificationBadgeComponent],
  imports: [CommonModule, BadgeOldModule, SvgIconModule, MatTooltipModule],
  exports: [CompanyVerificationBadgeComponent],
})
export class CompanyVerificationBadgeModule {}
