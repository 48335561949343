import { Component, HostBinding, Input } from '@angular/core';
import { Company, CompanyStatus } from 'src/models/company/company';

@Component({
  selector: 'app-company-verification-badge',
  templateUrl: './company-verification-badge.component.html',
  styleUrls: ['./company-verification-badge.component.scss'],
})
export class CompanyVerificationBadgeComponent {
  @Input() company: Company;
  @Input() iconSize: string = '10px';
  @Input() showOnActive: boolean = false;

  @HostBinding('class') get addCssClasses() {
    return this.showBadge ? '' : 'd-none';
  }

  showBadge: boolean;
  hasWarningIcon: boolean;
  backgroundColor: string;
  tooltip: string;

  ngOnChanges() {
    this.showBadge =
      this.company.isVerifiedBySF ||
      (this.company.status == CompanyStatus.ACTIVE && this.showOnActive);
    this.backgroundColor = this.company.isVerifiedBySF
      ? 'var(--blue-600)'
      : 'var(--neutral-400)';
    this.tooltip = this.company.isVerifiedBySF
      ? 'Verified company'
      : 'Get verification Badge';
  }
}
