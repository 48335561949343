<app-badge-old
  *ngIf="showBadge"
  class="verified-badge"
  [backgroundColor]="backgroundColor"
  [matTooltip]="tooltip">
  <app-svg-icon
    iconSrc="assets/icons/check-white.svg"
    [width]="iconSize"
    [height]="iconSize" />
</app-badge-old>
